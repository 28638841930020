export const TOGGLE_INFO = 'TOGGLE_INFO';
export const TOGGLE_CAMERA = 'TOGGLE_CAMERA';
export const TOGGLE_SECTION = 'TOGGLE_SECTION';
export const SELECT_BIOME = 'SELECT_BIOME';
export const CLOSE_BIOME = 'CLOSE_BIOME';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLOSE_MESSAGE = 'CLOSE_MESSAGE';
export const PLACE_GLOBE = 'PLACE_GLOBE';
export const RESET_GLOBE = 'RESET_GLOBE';
export const TAKE_PICTURE = 'TAKE_PICTURE';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const HELP_DISPLAYED = 'HELP_DISPLAYED';
